
import './App.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import logo from "./img/logo.png"
import Avatar from '@mui/material/Avatar';
import banner from "./img/banner3.jpg"
import nft1 from "./img/NFT1.png"
import nft2 from "./img/NFT2.png"
import nft3 from "./img/NFT3.png"
import nft4 from "./img/NFT4.png"
import nft5 from "./img/NFT5.png"
import nft6 from "./img/NFT6.png"
import nft7 from "./img/NFT7.png"
import nft8 from "./img/NFT8.png"
import nft9 from "./img/NFT9.png"
import nft10 from "./img/NFT10.png"
import banana from "./img/banana.png"
import {makeStyles} from "@material-ui/core"
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import useMediaQuery from '@mui/material/useMediaQuery';
import twitter from "./img/twitter.png"
import discord from "./img/discord.png"
import metaDEV from "./img/metaDEV.png"
import metaNion from "./img/metaNion.png"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZF2avUM83955CjC1EEHsBwUlgNvTL-ic",
  authDomain: "apetosapes-4b19b.firebaseapp.com",
  projectId: "apetosapes-4b19b",
  storageBucket: "apetosapes-4b19b.appspot.com",
  messagingSenderId: "458137558500",
  appId: "1:458137558500:web:3dbd2dbb804a06c63cfbf1",
  measurementId: "G-X0FQ2JZ02T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  
  
  return (
    
  

    <div style={{width:"100vw",height:"auto",backgroundColor:"black"}}>
     
        <div style={{display:"flex",position:"fixed",flexDirection:"row",width:"100vw",height:"auto",backgroundColor:"black",alignItems:"center",zIndex:"1"}}> 

          <div style={{width:"100vw",textAlign:"start",marginLeft:"4vw"}}>

          <Avatar alt="Logo" src={logo}  sx={{ width: "5vw", height: "auto" }}/>


          </div>
          <div style={{display:"flex",flexDirection:"row",width:"100vw",textAlign:"end",marginRight:"4vw"}}>
                
                <Button href='#NFT' sx={{fontSize:"2vw",margin:"1vw",fontFamily:"VT323",color:"white",width:"6vw",height:"auto"}} >NFT's</Button>
                <Button href='#Roadmap' sx={{fontSize:"2vw",margin:"1vw",fontFamily:"VT323",color:"white",width:"6vw",height:"auto"}}>Roadmap</Button>
                <Button href='#Team' sx={{fontSize:"2vw",margin:"1vw",fontFamily:"VT323",color:"white",width:"6vw",height:"auto"}}>Team</Button>
                <Button href="https://stake.apetosapes.com/" sx={{fontSize:"2vw",margin:"1vw",fontFamily:"VT323",color:"white",width:"6vw",height:"auto"}}>Staking</Button>
                <Button sx={{fontSize:"2vw",margin:"1vw",fontFamily:"VT323",color:"white",":hover":{cursor:"not-allowed"},width:"6vw",height:"auto"}}>Games</Button>
                <Button sx={{fontSize:"2vw",margin:"1vw",fontFamily:"VT323",color:"white",":hover":{cursor:"not-allowed"},width:"6vw",height:"auto"}}>Launchpad</Button>
                <Button sx={{fontSize:"1.9vw",margin:"1vw",fontFamily:"VT323",color:"white",width:"10vw",":hover":{cursor:"not-allowed"}}}>Merch Store</Button>
                
          </div>
          
        
        </div>

        <div id='Banner' style={{width:"100vw",height:"auto",justifyContent:"center",textAlign:"center",backgroundColor:"black"}}>
           <img src={banner} style={{marginTop:"10vw",width:"100vw",height:"auto"}}></img>
        </div>

        <div id='NFT' style={{display:"flex",flexDirection:"column",width:"100vw",height:"auto",backgroundColor:"black",textAlign:"center"}}>
          
        
        <h1 style={{color:"white",fontFamily:"VT323",fontSize:"6vw"}}>NFT's</h1>

        <div style={{display:"flex",flexDirection:"row", width:"100vw",height:"auto",alignItems:"center",justifyContent:"center"}}>
        <Avatar alt="Logo" src={nft1}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft2}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft3}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft4}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft5}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        </div>
        <div style={{display:"flex",flexDirection:"row", width:"100vw",height:"auto",alignItems:"center",justifyContent:"center"}}>
        <Avatar alt="Logo" src={nft6}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft7}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft8}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft9}  sx={{width: "10vw", height: "auto" ,margin:"4vw"}}/>
        <Avatar alt="Logo" src={nft10}  sx={{ width: "10vw", height: "auto" ,margin:"4vw"}}/>
        </div>

        </div>

        <div id='Roadmap' style={{display:"flex",flexDirection:"column",width:"100vw",height:"auto",backgroundColor:"black",textAlign:"center"}}>
          
        
        <h1 style={{color:"white",fontFamily:"VT323",fontSize:"6vw"}}>Roadmap</h1>

        <div style={{display:"flex",flexDirection:"column",width:"100vw",height:"auto",backgroundColor:"black"}}>

            <div style={{display:"flex",flexDirection:"row",width:"100vw",backgroundColor:"black",backgroundColor:"transparent"}}>

            <div style={{display:"flex",flexDirection:"row",width:"50vw",height:"auto",backgroundColor:"black",justifyContent:"center",alignItems:"center",textAlign:"center",marginLeft:"10vw"}}>

            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"3vw"}}>November</h1>
            <div style={{display:"flex",flexDirection:"column",width:"100vw",marginLeft:"2vw"}}>
            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"1.8vw",maxWidth:"30vw"}}>WEBSITE+COMMUNITY BUILDING+STAKING</h1>
            <h1 style={{color:"orange",fontFamily:"VT323",fontSize:"1.2vw",maxWidth:"30vw"}}>Activating the new website, developing staking site,partnerships with major projects</h1>
            </div>

            </div>
            <div style={{display:"flex",flexDirection:"row",width:"50vw",height:"auto",backgroundColor:"black",justifyContent:"center",alignItems:"center",textAlign:"center",marginRight:"10vw"}}>

            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"3vw"}}>December</h1>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"2vw"}}>
            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"1.8vw",maxWidth:"30vw"}}>FIRST AIRDROP+P2E GAME</h1>
            <h1 style={{color:"orange",fontFamily:"VT323",fontSize:"1.2vw",maxWidth:"30vw"}}>New NFT airdrop for holders,the beginning of the P2E game</h1>
            </div>

            </div>


            </div>
            <div style={{display:"flex",flexDirection:"row",width:"100vw",backgroundColor:"black",backgroundColor:"transparent",marginTop:"10vw"}}>

            <div style={{display:"flex",flexDirection:"row",width:"50vw",height:"auto",backgroundColor:"black",justifyContent:"center",alignItems:"center",textAlign:"center",marginLeft:"10vw"}}>

            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"3vw"}}>January</h1>
            
            <div style={{display:"flex",flexDirection:"column",width:"100vw",marginLeft:"2vw"}}>
            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"1.8vw",maxWidth:"30vw"}}>SECOND AIRDROP+COMIC BOOK+MERCH SNEAK PEEK</h1>
            <h1 style={{color:"orange",fontFamily:"VT323",fontSize:"1.2vw",maxWidth:"30vw"}}>New NFT collection airdrop for holders,first introduction of comics and merchs</h1>
            </div>

            </div>
            <div style={{display:"flex",flexDirection:"row",width:"50vw",height:"auto",backgroundColor:"black",justifyContent:"center",alignItems:"center",textAlign:"center",marginRight:"10vw"}}>

            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"3vw"}}>February</h1>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"2vw"}}>
            <h1 style={{color:"white",fontFamily:"VT323",fontSize:"1.8vw",maxWidth:"30vw"}}>NEW P2E GAME+MARKETPLACE+LAUNCHPAD</h1>
            <h1 style={{color:"orange",fontFamily:"VT323",fontSize:"1.2vw",maxWidth:"30vw"}}>Adding new P2E game, activating marketplace and launchpad</h1>
            </div>

            </div>


            </div>

        </div>

        </div>

        <div id="Team" style={{display:"flex",flexDirection:"column",width:"100vw",height:"auto",backgroundColor:"black",textAlign:"center"}}>

        <h1 style={{color:"white",fontFamily:"VT323",fontSize:"6vw"}}>Team</h1>

        <div style={{display:"flex",flexDirection:"row",width:"100vw",height:"auto",textAlign:"center",justifyContent:"center"}}>

        <div style={{display:"flex",flexDirection:"column",width:"33vw",height:"auto",textAlign:"center",justifyContent:"center",alignItems:"center"}}>

        <Avatar alt="Logo" src={metaDEV}  sx={{width: "15vw", height: "auto",margin:"4vw"}}/>
        <h1 style={{color:"orange",fontFamily:"VT323",fontSize:"3vw",marginTop:"-3vw"}}>MetaDEV</h1>  
        <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",marginTop:"-2vw"}}>Lead Developer</h1>           

        </div>
        <div style={{display:"flex",flexDirection:"column",width:"33vw",height:"auto",textAlign:"center",justifyContent:"center",alignItems:"center"}}>

        <Avatar alt="Logo" src={nft1}  sx={{width: "15vw", height: "auto",margin:"4vw"}}/>
        <h1 style={{color:"orange",fontFamily:"VT323",fontSize:"3vw",marginTop:"-3vw"}}>R0meo</h1>  
        <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",marginTop:"-2vw"}}>Founder</h1>           

        </div>
        <div style={{display:"flex",flexDirection:"column",width:"33vw",height:"auto",textAlign:"center",justifyContent:"center",alignItems:"center"}}>

<Avatar alt="Logo" src={metaNion}  sx={{width: "15vw", height: "auto",margin:"4vw"}}/>
<h1 style={{color:"orange",fontFamily:"VT323",fontSize:"3vw",marginTop:"-3vw"}}>MetaNion</h1>  
<h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw",marginTop:"-2vw"}}>Project Manager</h1>           

        </div>
        
        

        </div>

        </div>

        <div id="footer" style={{display:"flex",flexDirection:"row",width:"100vw",height:"auto",justifyContent:"end",textAlign:"center",alignContent:"center",alignItems:"center",backgroundColor:"black"}}>

        <div style={{display:"flex",flexDirection:"column",width:"100vw",height:"auto",justifyContent:"center",textAlign:"center",marginTop:"10vw"}}>
        <h1 style={{color:"white",fontFamily:"VT323",fontSize:"2vw"}}>Powered by APTOS</h1>
        <div style={{display:"flex",flexDirection:"row",width:"100vw",height:"auto",justifyContent:"center",textAlign:"center",alignContent:"center"}}>
        <a target="_blank" href="https://twitter.com/ApetosApe"><img style={{width:"2vw",height:"auto"}} src={twitter} alt="" /></a>
        <a target="_blank" href="https://discord.gg/k4bk7afSBF"><img style={{width:"2vw",height:"auto"}}  src={discord} alt="" /></a>
        </div>
        
        </div>
        

        </div>
      
    </div>
  );
}

export default App;
